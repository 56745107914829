import { useState } from "react";
import {
  BodyMeasureImgs,
  BodyMeasuresImgsResponse,
} from "../../libs/measures/types";
import { getDay, getMonth } from "../../libs/utils/date";
import { Skeleton } from "../Skeleton";
import { DeleteButton, PlusButton } from "../Button";
import { BodyImg, BodyImgsModal, BodyImgsModalProps } from "./ImgsModal";
import {
  useDeleteHistoryMutation,
  useGetHistoryQuery,
} from "../../libs/history/api";
import { AddBodyImgsModal } from "./AddImgsModal";

type BodyTableProps = {
  userId?: number | null;
};

export const BodyTable: React.FC<BodyTableProps> = ({ userId }) => {
  const {
    data: bodyListToFilter,
    isLoading,
    error,
  } = useGetHistoryQuery(userId || -1, {
    selectFromResult: (result) => ({
      isLoading: result.isLoading as boolean,
      error: result.error,
      data: (result.data?.imgHistoryList || {}) as BodyMeasuresImgsResponse,
    }),
  });

  const [openShowImgModal, setOpenShowImgModalModal] =
    useState<BodyImgsModalProps["openModal"]>(false);

  const [openAddImgModal, setOpenAddImgModalModal] = useState(false);
  if (isLoading) {
    return <Skeleton />;
  }
  if (error || !bodyListToFilter) {
    return <div>Error...</div>;
  }

  const bodyList = Object.keys(bodyListToFilter).reduce((acc, date) => {
    const body = bodyListToFilter[date];
    if (body.imgs?.length && body?.imgs?.length > 0) {
      acc[date] = body;
    }
    return acc;
  }, {} as BodyMeasuresImgsResponse);

  const dateList = Object.keys(bodyList) || [];

  const noData = Object.keys(bodyList).length === 0 || dateList.length < 1;

  const columns = ["Data", "Immagini"];

  return (
    <div className="relative overflow-x-auto my-8">
      <PlusButton
        variant="default"
        onClick={() => setOpenAddImgModalModal(true)}
      >
        Aggiungi Immagini
      </PlusButton>
      {noData ? (
        <div className="text-xs text-gray-700 uppercase dark:text-gray-400 mt-8">
          Non ci sono misurazioni
        </div>
      ) : (
        <table className="w-full mt-4 text-sm text-gray-500 dark:text-gray-400 text-center">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr className="text-md">
              {columns.map((column) => (
                <th key={column} scope="col" className="px-6 py-3 text-center ">
                  {column}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dateList.map((date, index) => (
              <BodyRow
                key={index + "-body"}
                date={date}
                body={bodyList[date]}
                index={index}
                setOpenModal={setOpenShowImgModalModal}
              />
            ))}
          </tbody>
        </table>
      )}
      <BodyImgsModal
        openModal={openShowImgModal}
        setOpenModal={setOpenShowImgModalModal}
      />
      <AddBodyImgsModal
        openModal={openAddImgModal}
        setOpenModal={setOpenAddImgModalModal}
      />
    </div>
  );
};

type BodyRowProps = {
  body: BodyMeasureImgs;
  date: string;
  index: number;
  setOpenModal: (value: any) => void;
};
const BodyRow: React.FC<BodyRowProps> = ({
  body,
  date,
  index,
  setOpenModal,
}) => {
  const rowBgColor =
    index % 2 !== 0
      ? "bg-gray-50 dark:bg-gray-700"
      : "bg-white dark:bg-gray-900";

  const [deleteHistory, { isLoading: isLoadingDeleteHistory }] =
    useDeleteHistoryMutation();

  return (
    <tr className={`${rowBgColor} dark:border-gray-700`}>
      <th
        scope="row"
        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
      >
        <DeleteButton
          onClick={() => deleteHistory(body.historyId || -1)}
          isPending={isLoadingDeleteHistory}
          variant="icon"
        />
        {getDay(date)}, {date.split("-")[2]} {getMonth(date)}{" "}
        {date.split("-")[0]}
      </th>

      <td className="px-6 py-4 flex flex-col flex-wrap justify-left">
        {body.imgs && body.imgs.length > 0 && (
          <>
            <div className="flex justify-between items-start">
              {body.imgs.map((img, i) => (
                <BodyImg img={img} key={img + i} />
              ))}
            </div>

            <button
              type="button"
              className="text-gray-800 font-bold bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-2 mx-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={() =>
                setOpenModal({
                  imgs: body.imgs,
                  date: date,
                })
              }
            >
              SCHERMO INTERO
            </button>
          </>
        )}
      </td>
    </tr>
  );
};
